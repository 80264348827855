/*
Specify all the typographic styles that are going to be reused.
*/

html {
	color: map_get($colours, body-text);
	font-family: $font-family-body;
	font-size: 14pt;
	font-weight: $font-body-weight;
	letter-spacing: .05ch;

	@media all and (min-width: 600px) {
		font-size: 13pt;
	}
}


// #####         ### ##### #   #  #     ####   ###
//   #          #      #    # #   #     #     #
//   #           ##    #     #    #     ###    ##
//   #             #   #     #    #     #        #
//   #          ###    #     #    ####  ####  ###
//

.t {
	&-1 {
		margin-bottom: .2em;

		font-size: 24pt;
		line-height: 1.2;
	}

	&-2 {
		margin-bottom: .2em;

		font-size: 17pt;
		font-weight: $font-bold-weight;
		line-height: 1.2;
	}

	&-3 {
		font-size: 14pt;
		line-height: 1.1;
	}

	&-small {
		margin-bottom: .3em;

		font-size: 10pt;
		line-height: 1.2;
		text-transform: uppercase;
	}

	&--light {
	}

	&--regular {
		font-weight: $font-body-weight;
	}

	&--bold {
		font-weight: $font-bold-weight;
	}

	&--underline {
		margin-bottom: .4em;
		padding-bottom: .2em;

		border-bottom: solid 2px currentColor;
	}

	/*
	Used to keep words together. This is a sentence, keep <span class="t--widows">these words together</span>
	*/
	&--widows {
		white-space: nowrap;
	}
}

// #####   ##    ###         ###  #### ##### #####  ###   #  #   ###   ###
//   #    #  #  #           #     #      #     #     #    ## #  #     #
//   #    #  #  # ##         ##   ###    #     #     #    # ##  # ##   ##
//   #    ####  #  #           #  #      #     #     #    #  #  #  #     #
//   #    #  #   ###        ###   ####   #     #    ###   #  #   ###  ###
//

/*
Default styles to add to various tags
*/

blockquote {
	> p {
	}

	cite {
	}
}


b, strong {
	font-weight: $font-bold-weight;
}