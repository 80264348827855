//   ###  #  #   ###         ### ##### #   #  #     ####   ###
//  #     ####  #           #      #    # #   #     #     #
//  #     ####   ##          ##    #     #    #     ###    ##
//  #     #  #     #           #   #     #    #     #        #
//   ###  #  #  ###         ###    #     #    ####  ####  ###
//

/*
This is where we break a few rules. All HTML coming from the CMS should be wrapped in the .cms-content class. This is
used to sandbox the styles from the rest of the website. We target children of the class for styling.
*/

.cms-content {
	line-height: $line-height;

	p:not(:last-child), ol:not(:last-child), ul:not(:last-child) {
		margin-bottom: .7em;
	}

	a:link, a:visited {
		color: map_get($colours, peacock);
	}

	a:hover, a:active {

	}

	ul {
		margin-left: 1.5em;

		color: map_get($colours, sea-grey);
		list-style-type: none;

		&:not(:last-child) {
			padding: .6em 0;
		}

		li {
			margin-bottom: .4em;
		}
	}

	> ul {
		list-style-type: circle;
	}

	blockquote {
		margin: 1em auto;
		width: 85%;

		color: map_get($colours, peacock);
		font-size: 2em;
		line-height: 1.2;

		p {
			margin-bottom: 0 !important;
		}
	}
}


.cms-t-1 {
	@extend .t-1;

	font-weight: $font-bold-weight;
}

.cms-t-2 {
	@extend .t-2;
}

.cms-t-3 {
	@extend .t-3;

	margin-top: 1.3em;

	font-weight: $font-bold-weight;
}

.cms-column-list {
	width: 75%;

	column-count: 2;
}

.cms-cite {
	color: map_get($colours, mid-grey);
	font-size: 12pt;
	font-style: normal;
}