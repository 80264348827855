th {
	font-weight: $font-bold-weight;
}

.table {
	margin-bottom: 30px;

	th {
		padding: 15px 10px;
	}

	td {
		padding: 10px;
	}

	p {
		line-height: 1.2;

		&:not(:last-child) {
			margin-bottom: .2em;
		}
	}
}