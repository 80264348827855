

.news {
	&__author {
		color: map_get($colours, peacock);
	}

	&__date {
		color: map_get($colours, light-grey);
	}
}