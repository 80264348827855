

.pagination {
	align-items: center;
	display: flex;

	margin-bottom: 30px;

	font-weight: $font-bold-weight;

	&__item {
		padding: 0 10px;

		&--disabled {
			opacity: .5;
		}

		&:hover, &:active, &--active {
			color: map_get($colours, body-text);
		}

		&--first {
			&::after {
				content: '...';
			}
		}

		&--last {
			&::before {
				content: '...';
			}
		}
	}
}

