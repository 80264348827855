/*
RARE reset, this has additional rules to the standard normalize ones.
*/



//  #      ##  #   #   ##   #  # #####
//  #     #  #  # #   #  #  #  #   #
//  #     #  #   #    #  #  #  #   #
//  #     ####   #    #  #  #  #   #
//  ####  #  #   #     ##    ##    #
//

* {
	box-sizing: border-box;
}

/*
Lists in navigation don’t need bullets
*/

nav {
	ul {
		list-style-type: none;

		> li {
			list-style-type: none;
		}
	}
}



// ##### #   #  ###    ##    ###  ###    ##   ###   #  # #   #
//   #    # #   #  #  #  #  #     #  #  #  #  #  #  #  #  # #
//   #     #    ###   #  #  # ##  ###   #  #  ###   ####   #
//   #     #    #     #  #  #  #  # #   ####  #     #  #   #
//   #     #    #      ##    ###  #  #  #  #  #     #  #   #
//

/*
Reduce the standard line-height for everything so we can control the vertical rhythm better
*/

html, body {
	line-height: 1;
}

/*
We don’t want to make any assumptions over how the typography will be styled.
The tags are purely semantic and not an aid to the visual hierarchy.
*/

h1, h2, h3, h4, h5, h6, p, ul, ul, th, td, q, blockquote {
	margin: 0;
	padding: 0;

	color: inherit;
	font-family: inherit;
	font-size: inherit;
	font-style: inherit;
	font-weight: inherit;
	line-height: inherit;
}

figure {
	margin: 0;
}


table {
	width: 100%;

	border-collapse: collapse;
}

caption {
	padding: 5px;

	text-align: left;
}

th {
	padding: 5px;

	text-align: left;
}

td {
	padding: 5px;
}

/*
It’s not really smaller text, it’s used for supporting copy such as describing the format required for an input tag.
*/
small {
	font-size: inherit;
}


/*
Links should inherit the parent element colour by default
*/
a:link, a:visited {
	color: inherit;
	text-decoration: none;
}


//  #  #  ###    ###   ###  ####  #     #      ##   #  #  ####   ##   #  #   ###
//  ####   #    #     #     #     #     #     #  #  ## #  #     #  #  #  #  #
//  ####   #     ##   #     ###   #     #     #  #  # ##  ###   #  #  #  #   ##
//  #  #   #       #  #     #     #     #     ####  #  #  #     #  #  #  #     #
//  #  #  ###   ###    ###  ####  ####  ####  #  #  #  #  ####   ##    ##   ###
//

input, option, textarea {
	border: solid 1px #ccc;
}

legend {
	display: table;
	padding: 0;
}

fieldset {
	margin: 0;
	padding: 0.001em 0 0 0;
	min-width: 0;

	border: 0;
}

/*
Removes the bevels from the buttons
*/
button {
	border: none;
}

textarea {
	resize: vertical;
}

select {
	background-color: #fff;
	border-radius: inherit;

	-webkit-appearance: none;
}

body:not(:-moz-handler-blocked) fieldset {
	display: table-cell;
}

iframe {
	vertical-align: top;

	border: 0;
}

/*
Stops images have a weird little space below them
*/
img {
	vertical-align: top;
}

address {
	font-style: inherit;
}