

.hero {
	position: relative;
	height: 280px;

	background-color: map_get($colours, dark-blue);
	background-image: url(../../img/logo-lines.svg);
	background-repeat: no-repeat;
	background-position: 55% -70px;
	background-size: auto 158%;

	color: #fff;
	font-size: 20pt;

	&--tall {
		height: 400px;

		background-color: map_get($colours, dark-blue);
		background-image: url(../../img/logo-lines.svg);
		background-repeat: no-repeat;
		background-position: 80% -100px;
		background-size: auto 158%;

		@media all and (max-width: 600px) {
			height: 100vh;

			background-position: 80% 93%;
			background-size: auto 125%;
		}

		@media all and (min-width: 900px) {
			background-position: 72% -100px;
		}

		@media all and (min-width: 1200px) {
			background-position: 55% -100px;
		}
	}

	&--image {
		background-repeat: no-repeat;
		background-position: center;
		background-size: auto 158%, cover;

		@media all and (min-width: 940px) {
			background-position: center, 55% -100px;
		}
	}

	&--image-1 {
		background-image: url(../../img/logo-lines.svg), url(../../img/hero-1.jpg);
	}

	&--image-2 {
		background-image: url(../../img/logo-lines.svg), url(../../img/hero-2.jpg);
	}

	&--image-3 {
		background-image: url(../../img/logo-lines.svg), url(../../img/hero-3.jpg);
	}

	&__content {
		left: 120px;
		position: absolute;
		top: 150px;
		width: 250px;

		@media all and (max-width: 600px) {
			bottom: 25%;
			left: 20%;
			top: auto;
			width: 60%;
		}

		@media all and (min-width: 870px) {
			width: 380px;
		}
	}

	&__button {
		&:link, &:visited {
			color: #fff;
		}

		&:hover, &:active {
			background-color: rgba(0, 0, 0, .3)
		}
	}

	&__small {
		font-size: 12pt;
	}
}