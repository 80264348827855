

.p-map {
	height: 65vh;
	max-height: 380px;
}

.p-contact {
	@media all and (min-width: 730px) {
		display: flex;
	}

	&__address {
		@media all and (min-width: 730px) {
			align-items: flex-end;
			display: flex;
			justify-content: space-between;

			min-width: 320px;
		}
	}

	&__address-item {
		width: 50%;
	}

	&__form {
		@media all and (min-width: 730px) {
			align-items: flex-end;
			display: flex;
			flex-grow: 1;
			justify-content: space-between;
		}
	}

	&__fields {
		@media all and (max-width: 729px) {
			margin-bottom: 40px;
		}

		@media all and (min-width: 730px) {
			flex-grow: 1;

			margin-right: 40px;
		}
	}
}