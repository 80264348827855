/*
TODO ------- make this properly

Basic grid structure used for laying out grids, that’s actual proper grids of boxes. More complex layouts should be done
in their BEM blocks.

The grid cells by default have no spacing between them but a gutter can be added with a modifier. We use the negative
margin trick on the container of the grid to compensate for the margin between the cells. It’s simpler than using calc()
and better than padding both sides as that makes the grid narrower than the container.

The grid is purely a container. The actual content will usually be another element inside. This way no styles interfere
with the basic grid layout.

Basic grid

<div class="l-grid">
	<div class="l-grid__items">
		<div class="l-grid__item">
			<article>
				The content
			</article>
		</div>
		<div class="l-grid__item">
			<article>
				The content
			</article>
		</div>
	</div>
</div>
*/


/*
We need the extra container for clipping the sub item used for holding the grid cells as we use negative margins
*/

.l-grid {
	grid-gap: 40px;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;


	&--3-col {
		& > * {
			flex-basis: calc(33.333% - 40px);
			margin-bottom: 40px;
		}
	}

	&--4-col {
		& > * {
			flex-basis: calc(25% - 40px);
			margin-bottom: 40px;
		}
	}
}

@supports (display: grid) {
	.l-grid {
		display: grid;
		grid-gap: 40px;

		& > * {
			margin-bottom: 0;
		}

		&--3-col {
			grid-template-columns: repeat(3, 1fr);
		}

		&--4-col {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}


.l-grid {
	display: -ms-grid;

	-ms-grid-rows: auto 40px auto 40px auto 40px auto 40px auto 40px auto 40px auto;

	&--3-col {
		-ms-grid-columns: 1fr 40px 1fr 40px 1fr;

		& > * {
			$col: 1;
			$col-gutters: 0;
			$row: 1;
			$row-gutters: 0;

			@for $i from 1 through 200 {
				&:nth-child(#{$i}) {
					-ms-grid-column: #{$col + $col-gutters};
					-ms-grid-row: #{$row + $row-gutters};
				}

				$col-gutters: $col-gutters + 1;
				$col: $col + 1;

				@if ($col > 3) {
					$row-gutters: $row-gutters + 1;
					$row: $row + 1;
				}

				@if ($col > 3) {
					$col: 1;
					$col-gutters: 0;
				}

			}
		}
	}

	&--4-col {
		-ms-grid-columns: 1fr 40px 1fr 40px 1fr 40px 1fr;

		& > * {
			$col: 1;
			$col-gutters: 0;
			$row: 1;
			$row-gutters: 0;

			@for $i from 1 through 200 {
				&:nth-child(#{$i}) {
					-ms-grid-column: #{$col + $col-gutters};
					-ms-grid-row: #{$row + $row-gutters};
				}

				$col-gutters: $col-gutters + 1;
				$col: $col + 1;

				@if ($col > 4) {
					$row-gutters: $row-gutters + 1;
					$row: $row + 1;
				}

				@if ($col > 4) {
					$col: 1;
					$col-gutters: 0;
				}

			}
		}
	}
}
