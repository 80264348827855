

.footer {
	padding: 35px 0;

	color: #fff;
	font-size: 12pt;
	line-height: 1.4;

	@media all and (min-width: 680px) {
		align-items: last baseline;
		display: flex;
		justify-content: space-between;
	}

	&__links {
		@media all and (min-width: 580px) {
			display: flex;
			flex-wrap: wrap;
		}
	}

	&__link {
		margin-left: 2ch;

		@media all and (max-width: 580px) {
			margin-left: 0;
			width: 100%;
		}
	}

	&__registered {
		width: 100%;
	}

	&__bwi {
		filter: grayscale(100%);

		&:hover {
			filter: grayscale(0%);
		}
	}

	// I know it’s naughty but it’s the footer so unlikely to change much and if it does it’s by a dev
	p {
		margin-bottom: .3em;
	}
}