

.button {
	display: inline-block;
	padding: 12px 26px;

	background-color: transparent;
	border: solid 1px map_get($colours, peacock);

	color: inherit;
	font-family: inherit;
	font-size: 12pt;
	line-height: 1;

	cursor: pointer;

	transition: all .2s linear;

	&:link, &:visited {
		color: map_get($colours, mid-grey);
	}

	&:hover, &:active {
		background-color: transparentize(map_get($colours, dark-blue), .9);
	}
}

.signup-button {
	align-items: center;
	display: inline-flex;

	padding: 15px 18px;
	min-width: 280px;

	background-color: map_get($colours, dark-blue);
	border: solid 2px map_get($colours, peacock);

	color: #fff;
	font-size: 12pt;

	&:link, &:visited {
		color: #fff;
	}

	&:hover {
		.signup-button__arrow {
			fill: #fff;
		}
	}

	&--small {
		height: 50px;
	}

	&__label {
		margin-right: 6px;

		@media all and (min-width: 780px) {
			display: block;

			width: 244px;
		}
	}

	&__icon {
		height: 17px;

		@media all and (min-width: 780px) {
			height: 50px;
		}

		&--small {
			height: 30px;
		}
	}

	&__arrow {
		fill: map_get($colours, peacock);

		transition: all .2s ease-in-out;
	}
}