


.people {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	margin: 40px 0;
}

.person {
	margin-bottom: 20px;
	position: relative;
	width: 100%;

	background-color: map_get($colours, dark-blue);

	color: #fff;

	@media all and (min-width: 500px) {
		width: calc(50% - 10px);
	}

	@media all and (min-width: 700px) {
		width: calc(33.333% - 14px);
	}

	@media all and (min-width: 900px) {
		width: calc(25% - 16px);
	}

	&__image {
		/* temp stuff */
		//object-fit: none;
		object-position: top;

		margin-bottom: 10px;
		opacity: .9;

		transition: all .2s linear;

		&:hover {
			opacity: .8;
		}
	}

	&__details {
		display: flex;
		flex-direction: column;

		padding: 10px 14px 16px;

		font-size: .8em;

		&--grid {
			height: 110px;
		}
	}

	&__name {
		margin-bottom: .3em;

		font-size: 1.2em;
	}

	&__position {
		margin-bottom: 1em;

		color: map_get($colours, peacock);
		font-weight: $font-bold-weight;
		line-height: 1.1;
	}

	&__email {
		color: map_get($colours, peacock);
	}

	&__phone {
		margin-top: .5em;

		color: map_get($colours, peacock);
	}

  	&__blank {
	  	// Placeholder for empty person to balance person grid if not enough people to make full row
	  	// experiment: so that nice clean css of justify-content: space-between is not messed up with side margins
	  	background-color: transparent !important;
	}
}

.person-bio {
	@media all and (min-width: 700px) {
		display: flex;
	}

	&__details {
		align-self: flex-start;
		flex-shrink: 0;

		margin-bottom: 20px;
		margin-right: 30px;
		width: 300px;
	}
}