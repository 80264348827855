

.home-service {
	&__header {
		padding: 30px;
		background-color: map_get($colours, dark-blue);

		color: #fff;
		text-align: center;

		// dropping down to one column
		@media all and (min-width: 750px) {
			padding: 9% 6.5%;
		}
	}

	&__image {
		max-height: 350px;
		width: 100%;

		object-fit: cover;
		object-position: center;

		// dropping down to one column
		@media all and (max-width: 749px) {
			height: 60vh;
		}
	}
}

.home-recent-news {
	color: map_get($colours, light-grey);
	font-size: 30pt;
}