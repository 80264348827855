

.control {
	display: block;

	@media all and (min-width: 550px) {
		align-items: baseline;
		display: flex;
	}

	&:not(:last-child) {
		margin-bottom: 20px;

		@media all and (min-width: 550px) {
			margin-bottom: 40px;
		}
	}

	&:hover {
		.control__control {
			border: solid 1px map_get($colours, peacock);
		}
	}

	/*
	as we cover the default button with a box we need to wrap the <select> with an element so we can use it’s height
	for the pseudo element
	 */
	&__select {
		display: block;
		flex-grow: 1;

		position: relative;

		&:hover {
			&::after {
				background-image: url(../../img/icon-select--hover.svg);
			}
		}

		&::after {
			content: '';

			display: block;

			bottom: 1px;
			position: absolute;
			right: 1px;
			top: 1px;
			width: 30px;

			background-color: #fff;
			background-image: url(../../img/icon-select.svg);
			background-position: center;
			background-repeat: no-repeat;

			cursor: pointer;
			pointer-events: none;
		}
	}

	&__name {
		flex-shrink: 0;

		display: block;
		margin-bottom: 10px;
		width: 180px;

		cursor: pointer;
	}

	&__control {
		padding: 7px 9px;
		width: 100%;

		border: solid 1px #bbb;

		color: #555;
		font-family: system, sans-serif;

		transition: border .1s linear;

		&:focus {
			border: solid 1px map_get($colours, peacock);
		}

		&--textarea {
			height: 150px;
		}
	}

	&__error {
		display: block;
		margin-bottom: 10px;

		color: map_get($colours, invalid);

		font-size: .8em;
		line-height: 1.2;
	}
}

/*
These are rules that don’t need classes as they’re based on the HTML display
*/
input, select, textarea {
	&:required:invalid {
		box-shadow: none;

		background-image: url(../../img/icon-required.svg);
		background-position: calc(100% - 7px) 7px;
		background-repeat: no-repeat;
	}

	&:focus:invalid {
		border-color: map_get($colours, invalid) !important; // when focused and the control needs validation highlight when invalid
	}
}