

.media-block {
	display: flex;

	&--vertical {
		flex-direction: column;
	}

	&__content {
		display: flex;

		&--vertical {
			flex-grow: 1;
			flex-direction: column;
		}
	}
}