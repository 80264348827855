
.content {
	min-height: 600px;
}

.highlight {
	color: map_get($colours, mid-grey);
	font-size: 16pt;
	line-height: 1.25;

	@media all and (min-width: 780px) {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}

	@media all and (min-width: 840px) {
		font-size: 18pt;
	}

	&__body {
		margin-bottom: 20px;

		@media all and (min-width: 780px) {
			margin-right: 60px;
		}
	}
}

.three-columns {
	@media all and (min-width: 750px) {
		display: flex;
		justify-content: space-between;
	}

	&__column {
		display: block;

		@media all and (max-width: 749px) {
			margin-bottom: 25px;
		}

		@media all and (min-width: 750px) {
			width: calc(33.333% - 16.666px); // 16.666 = 50px / 3, giving 25px between each box
		}
	}
}