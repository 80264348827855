
.header {
	height: 84px;
	padding: 25px 0;
	position: fixed;
	width: 100%;
	z-index: 100;

	background-color: #fff;

	text-align: center;

	transition: all .2s ease-in-out;

	@media all and (min-width: 990px) {
		height: 134px;
		padding: 59px 0 40px;

	}

	@media all and (min-width: 1030px) {
		height: 137px;
	}

	&--scrolled {
		height: 84px;
		padding: 25px 0;

		box-shadow: 0 0 9px rgba(0, 0, 0, .1);
	}

	&__logo {
		height: 100%;
	}
}

/*
Common
*/
.nav {
	text-transform: uppercase;

	&__home {
		display: inline-block;
	}

	&__item {
		&:link, &:visited {
			color: map_get($colours, dark-blue);
		}

		&:hover, &:active, &--active {
			color: map_get($colours, peacock);
		}

		&--active {
			color: map_get($colours, peacock);

			&:link, &:visited {
				color: map_get($colours, peacock);
			}
		}
	}
}

/*
Mobile
*/
@media all and (max-width: 989px) {
	.nav {
		&__home {
			height: 30px;
		}

		&__items {
			display: flex;
			flex-direction: column;
			justify-content: center;

			height: 100vh;
			left: 0;
			padding-bottom: 20px; // pushes the text up a little to visually sit better
			position: absolute;
			top: 0;
			width: 100vw;
			z-index: 99;

			background-color: #fff;

			font-size: 17pt;

			transform: translateY(-100vh);
			transition: all .4s ease-in-out;

			&--open {
				transform: translateY(0);
			}
		}

		&__item {
			display: block;

			margin: 1.5vh 0;
			padding: 5px;
		}
	}
}


/*
Desktop
*/
@media all and (min-width: 990px) {
	.nav {
		align-items: flex-end;
		display: flex;
		justify-content: space-between;

		height: 100%;

		font-size: 12pt;

		&__home {
			height: 100%;
		}

		&__items {
			margin-bottom: 5px;
		}

		&__item {
			padding: 15px 6px;

			@media all and (min-width: 1030px) {
				padding: 15px 10px;
			}

			&:last-of-type {
				padding-right: 0;
			}

			// hide the home link on desktop
			&--home {
				display: none;
			}
		}
	}
}

.fixed-header-pusher {
	height: 84px;

	@media all and (min-width: 990px) {
		height: 135px;
	}

	@media all and (min-width: 1030px) {
		height: 138px;
	}
}

.nav-toggle {
	position: absolute;
	right: 4%;
	top: 30px;
	z-index: 100;
}

/*
As the burger is injects with JS let’s animate it in, each bar is staggered.
We’ll also animate the hover
*/
.burger {
	cursor: pointer;

	line {
		stroke-dasharray: 30;
		transition: all .2s ease-out;

		animation: .2s linear showBurger backwards;
	}

	line:nth-child(2) {
		animation-delay: .1s;
	}

	line:nth-child(3) {
		animation-delay: .2s;
	}

	/*
	Animate the lengths of the burder on hover
	*/
	&--closed {
		&:hover {
			line {
				stroke-dasharray: 22;
				stroke-dashoffset: 0;
			}

			line:nth-child(2) {
				stroke-dasharray: 20;
				stroke-dashoffset: 0;
			}

			line:nth-child(3) {
				stroke-dasharray: 25;
				stroke-dashoffset: 0;
			}
		}
	}

	/*
	turn it into a cross when open
	*/
	&--open {
		line:nth-child(1) {
			transform: rotate(45deg);
			transform-origin: 2px 6px;

			stroke-dasharray: 27;
		}

		line:nth-child(2) {
			stroke-dashoffset: 30;
		}

		line:nth-child(3) {
			transform: rotate(-45deg);
			transform-origin: 3px 17px;

			stroke-dasharray: 27;
		}
	}
}

@keyframes showBurger {
	from {
		stroke-dashoffset: 30;
	}

	to {
		stroke-dashoffset: 0;
	}
}