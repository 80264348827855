//   ###  #### ##### #####  ###   #  #   ###   ###
//  #     #      #     #     #    ## #  #     #
//   ##   ###    #     #     #    # ##  # ##   ##
//     #  #      #     #     #    #  #  #  #     #
//  ###   ####   #     #    ###   #  #   ###  ###
//

$debug: false;

$colours: (
	body-text: #222,
	white: #fff,
	dark-blue: #092737,
	peacock: #259a9f,
	off-white: #f5f5f5,
	light-grey: #c1c0c0,
	mid-grey: #7c7b7b,
	sea-grey: #717f8c,
	invalid: #c00
);

/*
Font: Semplicita Pro, hosted with Typekit in Ric’s account.
Regular: 400
Semi-bold: 600
*/
@import url("https://use.typekit.net/ymm0dqi.css");

$font-family-body: "semplicitapro", Arial, sans-serif;
$font-body-weight: 400;
$font-bold-weight: 600;

// Standard line-height for content
$line-height: 1.6;

// All the margin and padding utility classes to be created
$spaces: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200;

// Widths of the spans
$widths: (
	narrow: 900px,
	medium: 1000px,
	nav: 1030px,
	wide: 1200px,
);

::selection {
	background-color: #666;

	color: #fff;
}